<template>
    <v-row class="mt-4 justify-center">
        <v-col v-if="action == 1" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card
                    class="builder__container box-shadow d-flex flex-column justify-space-between"
                    color="white"
                    min-height="600"
                >
                    <v-card-text class="fill-height">
                        <project-sensors
                            v-bind.sync="projectSensors"
                        ></project-sensors>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            x-large
                            :dark="validSensors ? false : true"
                            outlined
                            :disabled="validSensors"
                            color="primary darken-3"
                            @click="next()"
                        >
                            {{ $t(`builder.actions.next`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
        <v-col v-if="action == 2" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card
                    flat
                    class="builder__container box-shadow"
                    color="white"
                >
                    <v-card-text>
                        <project-name
                            :project-name.sync="project.title"
                            :in-valid.sync="invalidName"
                            :label="$t(`builder.components.title`)"
                        ></project-name>
                        <select-project-media
                            :file.sync="project.media"
                            :file-type.sync="fileType"
                            :in-valid.sync="invalidMedia"
                            :type="+buildType"
                        ></select-project-media>
                        <project-description
                            v-if="showDescription"
                            :project-description.sync="project.description"
                            :in-valid.sync="invalidDescription"
                            :label="$t(`builder.components.description`)"
                        ></project-description>
                        <add-ingredients
                            v-if="showAddingridients"
                            class="mt-6"
                            :list.sync="project.items"
                        ></add-ingredients>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            x-large
                            :dark="validFirstSection ? false : true"
                            outlined
                            :disabled="validFirstSection"
                            color="primary darken-3"
                            @click="next()"
                        >
                            {{ $t(`builder.actions.next`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
        <v-col v-if="action == 3" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card flat color="appBackground">
                    <v-card-text>
                        <stepper
                            :steps-value.sync="steps"
                            :get-stepper="stepper"
                            :get-step="steps"
                        >
                            <template #stepMedia>
                                <step-image
                                    v-if="showStepImage"
                                    :step-image.sync="projectStep.stepImage"
                                ></step-image>
                            </template>
                            <template #stepDescription>
                                <project-description
                                    :project-description.sync="
                                        projectStep.description
                                    "
                                    :label="
                                        $t(
                                            `builder.components.stepDescription`,
                                            {
                                                step: stepper,
                                            }
                                        )
                                    "
                                ></project-description>
                            </template>
                            <template #action>
                                <v-btn
                                    depressed
                                    dense
                                    dark
                                    color="primary"
                                    @click="saveStep()"
                                >
                                    {{ $t(`builder.actions.save`) }}
                                </v-btn>
                            </template>
                        </stepper>
                        <div class="mt-10">
                            <v-btn
                                depressed
                                dense
                                color="primary"
                                class="my-2"
                                :disabled="valiSecondSection"
                                @click="addStep()"
                            >
                                {{ $t(`builder.actions.addStep`) }}
                                <v-icon class="ml-2" size="20">
                                    fa-solid fa-plus
                                </v-icon>
                            </v-btn>

                            <step-chips
                                class="my-2"
                                :stepper.sync="stepper"
                                :steps="steps"
                                @remove-step="removeStep($event)"
                            ></step-chips>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            x-large
                            :dark="valiSecondSection ? false : true"
                            outlined
                            :disabled="valiSecondSection"
                            color="primary darken-3"
                            @click="next()"
                        >
                            {{ $t(`builder.actions.finish`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
        <v-col v-if="action == 4" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card
                    flat
                    class="d-flex flex-column align-center centered"
                    color="appBackground"
                >
                    <v-card-text class="d-flex flex-column align-center">
                        <hero :size="140"></hero>
                        <div class="mt-6">
                            <h2>{{ $t(`builder.finishTxt`) }}</h2>
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            @click="saveAsDraft()"
                        >
                            {{ $t(`builder.actions.save`) }}
                        </v-btn>
                        <v-btn
                            depressed
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            @click="publish()"
                        >
                            {{ $t(`builder.actions.publish`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { createProject, createSensor } from "@/api/projectsApi.js";
import { getRole } from "@/helpers/generalHelpers.js";
import { mapState } from "vuex";

import ProjectName from "@/components/Builder/ProjectName.vue";
import ProjectDescription from "@/components/Builder/ProjectDescription.vue";
import AddIngredients from "@/components/Builder/AddIngredients.vue";
import Hero from "@/components/Heroes/Hero.vue";
import SelectProjectMedia from "@/components/Builder/SelectProjectMedia.vue";
export default {
    name: "ProjectBuilder",

    components: {
        ProjectName,
        ProjectDescription,
        AddIngredients,
        SelectProjectMedia,
        Hero,
        ProjectSensors: () => import("@/components/General/ProjectSensors.vue"),
        Stepper: () => import("@/components/Builder/Stepper.vue"),
        StepChips: () => import("@/components/Builder/StepChips.vue"),
        StepImage: () => import("@/components/Builder/StepImage.vue"),
    },

    data() {
        return {
            fileType: null,
            project: {},
            action: 1,
            stepper: 1,
            steps: 1,
            projectStep: {},
            projectSteps: [
                {
                    stepImage: null,
                    description: null,
                },
            ],
            defaultStep: {
                stepImage: null,
                description: null,
            },
            stepImages: [],
            projectSensors: {
                hasSensor: false,
                selectedPlanting: null,
                selectedSensor: null,
                sensorLocation: null,
                sensorAccess: "",
                isValid: true,
            },
            invalidMedia: true,
            invalidName: true,
            invalidDescription: true,
            buildType: null,

            createProjectStatus: apiStatus.Idle,
            createSensorStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["createProjectStatus", "createSensorStatus"]),
        showAddingridients() {
            if (this.fileType == "youtube_url" && this.buildType == 4) {
                return false;
            }
            if (this.fileType == "youtube_url" && this.buildType == 5) {
                return false;
            }
            if (this.buildType == 4) {
                return false;
            }
            if (this.buildType == 5) {
                return false;
            }
            return true;
        },

        validSensors() {
            return !this.projectSensors.isValid;
        },
        validFirstSection() {
            if (this.invalidMedia) {
                return true;
            }
            if (!this.project.media) {
                return true;
            }
            if (!this.project.title) {
                return true;
            }
            if (!this.project.description) {
                if (this.fileType != "youtube_url" && this.buildType != 4) {
                    return true;
                }
            }
            if (!this.project.items && this.showAddingridients) {
                return true;
            }

            return false;
        },
        valiSecondSection() {
            if (+this.buildType === 3) {
                const steps = this.projectSteps;
                for (let i = 0; i < steps.length; i++) {
                    if (!steps[i].description) return true;
                }
                return false;
            }

            let invalid = false;
            if (this.projectSteps.length == 0) {
                invalid = true;
            }
            if (this.projectSteps.length < this.steps) {
                return true;
            }
            if (this.stepImages.length == 0) {
                invalid = true;
            }
            if (this.stepImages.length < this.projectSteps.length) {
                invalid = true;
            }

            this.stepImages.forEach((image) => {
                if (!image) invalid = true;
            });

            this.projectSteps.forEach((step) => {
                if (!step.description) invalid = true;
            });

            return invalid;
        },
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),

        showDescription() {
            if (this.fileType == "youtube_url") {
                return false;
            }
            if (this.buildType == 4) {
                return false;
            }
            return true;
        },
        showStepImage() {
            if (this.buildType == 3) {
                return false;
            }
            return true;
        },
    },

    methods: {
        async createWithImage(status) {
            this.createProjectStatus = apiStatus.Pending;

            let data = new FormData();
            if (this.$route.params.type != 4 && this.$route.params.type != 5) {
                data.append("title", this.project.title);
                data.append("image_url", this.project.media);
                data.append("description", this.project.description);

                data.append("number_of_ingredients", this.project.items.length);
                this.project.items.forEach((item, idx) => {
                    data.append(`ingredients_${idx + 1}`, item);
                });
                data.append("number_of_steps", this.projectSteps.length);
                this.projectSteps.forEach((item, idx) => {
                    data.append(
                        `step_description_${idx + 1}`,
                        item.description
                    );
                });

                data.append("project_category_id", this.$route.params.type);
                data.append("status", status);
                for (let i = 0; i < this.stepImages.length; i++) {
                    if (this.$route.params.type != 3) {
                        data.append(`step_image_${i + 1}`, this.stepImages[i]);
                    }
                }
            }
            if (this.$route.params.type == 4) {
                data.append("title", this.project.title);
                data.append("library_file", this.project.media);
                data.append("status", status);
            }

            if (this.$route.params.type == 5) {
                data.append("title", this.project.title);
                data.append("image_url", this.project.media);
                data.append("status", status);
                data.append("description", this.project.description);
            }

            let url = `${getRole(this.user_role)}/${this.getType()}`;

            const { response, error } = await withAsync(
                createProject,
                data,
                url
            );

            if (error) {
                this.createProjectStatus = apiStatus.Error;
                return;
            }

            this.createProjectStatus = apiStatus.Success;
            return response.data.data?.project_id || -1;
        },
        async createWithVideo(status) {
            this.createProjectStatus = apiStatus.Pending;

            let payload;
            if (this.$route.params.type == 1 || this.$route.params.type == 2) {
                payload = {
                    title: this.project.title,
                    youtube_url: this.project.media,
                    ingredients: this.project.items,
                    project_category_id: this.$route.params.type,
                    status: status,
                };
            }

            if (this.$route.params.type == 3) {
                payload = {
                    title: this.project.title,
                    youtube_url: this.project.media,
                    status: status,
                    ingredients: this.project.items,
                };
            }

            if (this.$route.params.type == 4 || this.$route.params.type == 5) {
                payload = {
                    title: this.project.title,
                    youtube_url: this.project.media,
                    status: status,
                };
            }

            let url = `${getRole(this.user_role)}/${this.getType()}`;

            const { response, error } = await withAsync(
                createProject,
                payload,
                url
            );

            if (error) {
                this.createProjectStatus = apiStatus.Error;
                return;
            }

            this.createProjectStatus = apiStatus.Success;
            return response.data.data?.project_id || -1;
        },

        async createSensor(id) {
            this.createSensorStatus = apiStatus.Pending;

            const payload = {
                ...this.projectSensors.selectedSensor,
                access_key: this.projectSensors.sensorAccess,
                project_id: id,
            };

            const { error, response } = await withAsync(createSensor, payload);

            if (error) {
                console.log(error.response);
                this.createSensorStatus = apiStatus.Error;
                return;
            }

            this.createSensorStatus = apiStatus.Success;
        },
        next() {
            if (this.action == 1) {
                this.action = 2;
                return;
            }
            if (this.fileType == "youtube_url") {
                this.action = 4;
                return;
            }
            if (this.$route.params.type == 4 || this.$route.params.type == 5) {
                this.action = 4;
                return;
            }
            this.action++;
        },

        saveStep() {
            let data = { ...this.projectStep };
            this.projectSteps.splice(this.stepper - 1, 1, data);
            this.stepImages.splice(this.stepper - 1, 1, data.stepImage);
        },

        addStep() {
            this.steps = this.steps + 1;
            this.projectSteps.push({ ...this.defaultStep });
        },

        removeStep(event) {
            this.steps = this.steps - 1;
            this.stepper = event - 1;
            this.projectSteps.splice(this.stepper, 1);
            this.stepImages.splice(this.stepper, 1);
        },

        async saveAsDraft() {
            let status = 0;
            let project;
            this.fileType == "image_url"
                ? (project = await this.createWithImage(status))
                : (project = await this.createWithVideo(status));

            if (
                this.getType() === "library" ||
                this.getType() === "advice" ||
                this.getType() === "recipe"
            )
                return this.$router.push({ name: "MyProjects" });

            if (project === -1) return;
            if (this.projectSensors.hasSensor) {
                this.createSensor(project);
            }
            this.$router.push({ name: "MyProjects" });
        },
        async publish() {
            let project;
            let status = 1;
            this.fileType == "image_url"
                ? (project = await this.createWithImage(status))
                : (project = this.createWithVideo(status));
            if (
                this.getType() === "library" ||
                this.getType() === "advice" ||
                this.getType() === "recipe"
            )
                return this.$router.push({ name: "MyProjects" });

            if (project === -1) return;
            if (this.projectSensors.hasSensor) {
                this.createSensor(project);
            }

            this.$router.push({ name: "MyProjects" });
        },

        getType() {
            switch (this.$route.params.type) {
                case 1:
                    return "project";
                case 2:
                    return "project";
                case 3:
                    return "recipe";
                case 4:
                    return "library";
                case 5:
                    return "advice";
                default:
                    return "project";
            }
        },
    },

    watch: {
        stepper(newVal) {
            this.projectStep = { ...this.projectSteps[newVal - 1] };
        },
        fileType(newVal) {
            if (newVal) this.project.media = null;
        },
    },

    created() {
        this.buildType = this.$route.params.type;
        this.projectStep = { ...this.projectSteps[0] };
        if (this.buildType == 1) {
            this.action = 1;
            return;
        }
        this.action = 2;
    },
};
</script>

<style lang="scss" scoped>
.builder__container {
    padding: 12px;
}

.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
</style>
