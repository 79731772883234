import { api } from "./api";

const URLS = {
    projects: "projects",
    project: "project",
    createProject: "user/project",
    createSensor: "sensor",
};

export const getAllProjects = () => {
    return api.get(URLS.projects);
};

export const getProject = (payload) => {
    return api.get(`${URLS.project}/${payload.id}`);
};

export const likeProject = (payload, url) => {
    return api.post(url, payload);
};

export const bookmarkProject = (payload, url) => {
    return api.post(url, payload);
};

export const createProject = (payload, url) => {
    if (payload.youtube_url) {
        return api.post(url, payload);
    }
    const HEADERS = {
        "Content-Type": "multipart/form-data",
    };
    return api.post(url, payload, { headers: HEADERS });
};

export const getMyProjects = (url) => {
    return api.get(url);
};

export const getMyProject = (url) => {
    return api.get(url);
};

export const updateProject = (payload, url) => {
    return api.post(url, payload.data);
};

export const deleteProject = (url) => {
    return api.delete(url);
};

export const createSensor = (payload) => {
    return api.post(`${URLS.createSensor}`, payload);
};
